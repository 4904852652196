import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import findImage from '../../utils/findImage'
import Layout from '../../components/Layout'
import FullPage from '../../components/FullPage'
import WorkTitleBlock from '../../components/WorkTitleBlock'

export const frontmatter = {
  title: 'Kupuy Logo',
  type: 'Identity',
  client: 'Minfin',
  path: '/works/identity/kupuy-logo/',
  date: '2013-11-25',
  image: 'kupuy-logo-demo',
  favorite: true,
  work_type: 'Site',
}

export default ({ location }) => {
  const query = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: { relativePath: { regex: "^/kupuy/i/" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )
  const images = query.images.edges

  return (
    <Layout location={location}>
      <Helmet title={frontmatter.title} />

      <FullPage>
        <figure className="text-center">
          <Image
            fluid={findImage(images, 'kupuy-logo-big')}
            alt="Kupuy logo"
            width="786"
            height="124"
          />
        </figure>
      </FullPage>

      <WorkTitleBlock {...frontmatter} />
    </Layout>
  )
}
